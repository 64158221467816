import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import {
  createAdConfigSlice,
  createBrandSlice,
  createCuratedImagesSlice,
  createFastTvSlice,
  createGooglePublisherTagSlice,
  createJwtSlice,
  createMmyVehicleDataSlice,
  createNavigationSlice,
  createStickySlice,
  createUserEventsSlice,
  createUserProfileSlice,
  createUserSlice,
  createVehicleTrimSpecsSlice,
  createVideoManagerSlice,
  createZipCodeSlice,
} from '@/store/slices';
import {
  type AdConfigSlice,
  type BrandSlice,
  type CuratedImagesSlice,
  type FastTvSlice,
  type GooglePublisherTagSlice,
  type JwtSlice,
  type MmyVehicleDataSlice,
  type NavigationSlice,
  type StickySlice,
  type UserEventsSlice,
  type UserProfileSlice,
  type UserSlice,
  type VehicleTrimSpecsSlice,
  type VideoManagerSlice,
  type ZipCodeSlice,
} from '@/store/typings';

const useStore = create<
  UserSlice &
    AdConfigSlice &
    BrandSlice &
    ZipCodeSlice &
    JwtSlice &
    UserProfileSlice &
    VideoManagerSlice &
    StickySlice &
    FastTvSlice &
    CuratedImagesSlice &
    MmyVehicleDataSlice &
    GooglePublisherTagSlice &
    UserEventsSlice &
    NavigationSlice &
    VehicleTrimSpecsSlice
>()(
  devtools(
    subscribeWithSelector((...a) => ({
      ...createAdConfigSlice(...a),
      ...createBrandSlice(...a),
      ...createZipCodeSlice(...a),
      ...createUserSlice(...a),
      ...createJwtSlice(...a),
      ...createVideoManagerSlice(...a),
      ...createUserProfileSlice(...a),
      ...createStickySlice(...a),
      ...createFastTvSlice(...a),
      ...createCuratedImagesSlice(...a),
      ...createMmyVehicleDataSlice(...a),
      ...createGooglePublisherTagSlice(...a),
      ...createUserEventsSlice(...a),
      ...createNavigationSlice(...a),
      ...createVehicleTrimSpecsSlice(...a),
    })),
  ),
);

export default useStore;
